.service-bubble {
	background-image: url(/images/service-bubble.png);
	background-repeat: no-repeat;
	background-position: 100%;

	@media (max-width: 991px) {
		background-size: 200px;
		background-position-y: 100%;
	}
}
