/* LAYOUT: FOOTER
-------------------------------------------- */

.ptf-footer {
	position: relative;
}

/* SECTION: FOOTER STYLE 1
-------------------------------------------- */

.ptf-footer--style-1 {
	background-color: var(--ptf-color-9);
	background-image: url(/images/footer-bubble.png);
	background-repeat: no-repeat;
	background-position: calc(50% - 190px) 100%;
	background-size: 565px 340px;

	.ptf-footer__top {
		padding-top: px2rem(150px);
		padding-bottom: px2rem(40px);

		@include mq('medium') {
			padding-top: px2rem(75px);
			padding-bottom: px2rem(10px);
		}

		[class^='col'] {
			margin-bottom: px2rem(80px);

			@include mq('medium') {
				margin-bottom: px2rem(60px);
			}
		}

		.ptf-footer-heading {
			font-size: px2rem(28px);
			line-height: 1.42;
			font-weight: 600;

			@include mq('medium') {
				font-size: px2rem(28px);
			}
		}

		.ptf-widget + .ptf-widget {
			margin-top: px2rem(70px);
		}

		.ptf-widget-title {
			margin-bottom: px2rem(40px);
		}

		.ptf-footer-heading + .ptf-footer-heading {
			margin-top: px2rem(25px);
			margin-bottom: px2rem(35px);

			@include mq('medium') {
				margin-top: px2rem(40px);
			}
		}
	}

	.ptf-footer__bottom {
		padding-top: px2rem(65px);
		padding-bottom: px2rem(45px);

		border-top: 2px solid var(--ptf-color-black);

		@include mq('medium') {
			padding-bottom: px2rem(30px);
		}

		[class^='col'] {
			margin-bottom: px2rem(30px);
		}
	}
}

/* SECTION: FOOTER LANDING
-------------------------------------------- */

.ptf-footer--landing {
	color: var(--ptf-color-white);
	background-color: #282828;
	background-repeat: no-repeat;
	background-position: 50% 100%;

	h2 {
		color: inherit;
	}

	.ptf-footer__price {
		position: relative;
		display: inline-block;
		font-size: px2rem(210px);
		font-weight: 800;
		line-height: 1;
		@media (max-width: 576px) {
			font-size: px2rem(150px);
		}
		span {
			position: absolute;
			top: 0.25em;
			right: 100%;
			font-size: px2rem(60px);
			font-weight: 700;
			vertical-align: top;
		}
	}
}
