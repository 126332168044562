/* WIDGET: NEWS SLIDER
-------------------------------------------- */

.ptf-news-slider {
  .ptf-slider-controls {
    position: absolute;
    right: px2rem(75px);
    bottom: px2rem(70px);
    z-index: 2;

    @include mq("medium") {
      display: none;
    }
  }
}

.ptf-news-slide {
  position: relative;

  &__media {
    height: px2rem(760px);

    @include mq("large") {
      height: px2rem(550px);
    }

    img {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      /* support for plugin https://github.com/bfred-it/object-fit-images */

      font-family: "object-fit: cover;";

      object-fit: cover;
    }

    &::after {
      content: "";

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;

      background-image: linear-gradient(
        to top,
        hsla(0, 0%, 0%, 0.5),
        transparent 55%
      );
    }
  }

  &__content {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    max-width: px2rem(865px);
    padding: 0 px2rem(40px) px2rem(60px) px2rem(75px);
    transition: opacity var(--ptf-transition-duration) 1000ms
      var(--ptf-transition-easing);

    @include mq("large") {
      padding: 0 px2rem(40px) px2rem(80px) px2rem(40px);
    }

    .swiper-slide-active & {
      opacity: 1;

      transition-delay: 500ms;
    }
  }

  &__meta {
    margin-bottom: px2rem(15px);

    text-transform: uppercase;
    color: var(--ptf-color-white);
    font-size: px2rem(14px);

    span.cat {
      font-weight: 700;
    }

    span + span {
      &::before {
        content: "/";

        margin-right: px2rem(8px);
        margin-left: px2rem(8px);
      }
    }
  }

  &__title {
    color: var(--ptf-color-white);
    font-size: px2rem(48px);
    line-height: 1.16;

    @include mq("large") {
      font-size: px2rem(36px);
    }
  }

  &__excerpt {
    margin-top: px2rem(25px);

    color: var(--ptf-color-1);
  }
}
