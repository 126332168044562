/* SECTION: PAGE CONTACT
-------------------------------------------- */

.ptf-page--contact {
	background-repeat: no-repeat;
	background-position: 100% 130px;
}

/* SECTION: PAGE SERVICE DETAIL
-------------------------------------------- */

.ptf-page--service-detail {
	.ptf-skill-box-grid {
		display: grid;

		grid-template-columns: repeat(5, 1fr);
		grid-gap: px2rem(20px);
		@include mq('large') {
			grid-template-columns: repeat(4, 1fr);
		}
		@include mq('medium') {
			grid-template-columns: repeat(3, 1fr);
		}
		@include mq('small') {
			grid-template-columns: repeat(2, 1fr);
		}
	}
}

/* SECTION: PAGE BLOG MASONRY
-------------------------------------------- */

.ptf-page--blog-masonry .container-xxl--custom {
	@media (min-width: 1580px) {
		max-width: 100% !important;
		padding: 0 px2rem(60px);
	}
}

.ptf-btn.ptf-btn--underlined {
	font-size: 1rem;
	padding: 0.5625rem 0;
	color: var(--ptf-color-black);
	-webkit-box-shadow: inset 0 -2px currentColor;
	box-shadow: inset 0 -2px currentColor;
}
.ptf-twitter-review__content span {
	color: #0cf;
}
.scroll-top {
	width: 45px;
	height: 45px;
	position: fixed;
	bottom: 20px;
	right: 5px;
	z-index: 99;
	text-align: center;
	color: #fff;
	font-size: 18px;
	cursor: pointer;
	border-radius: 50%;
	background: #000;
	transition: all 0.3s ease-in-out;
	border: none;
	outline: none;
	&::after {
		position: absolute;
		z-index: -1;
		content: '';
		top: 100%;
		left: 5%;
		height: 10px;
		width: 90%;
		opacity: 1;
		background: radial-gradient(
			ellipse at center,
			rgba(0, 0, 0, 0.25) 0,
			transparent 80%
		);
	}
}

.testimonial-100-spacer {
	.slick-slider {
		margin: 0 -100px;
		padding-bottom: 60px;
		@media (max-width: 991px) {
			margin: 0 -30px;
		}
	}
	.slick-initialized .slick-slide {
		padding: 0 100px;
		@media (max-width: 991px) {
			padding: 0 30px;
		}
	}
	.slick-prev,
	.slick-next {
		top: 50%;
	}
}
.invalid-feedback {
	font-size: 1rem;
}
.agreement-checkbox
	input[type='checkbox']:checked
	+ label
	.ptf-checkbox__checkmark {
	background-color: var(--ptf-accent-1);
	-webkit-box-shadow: inset 0 0 0 1px var(--ptf-accent-1);
	box-shadow: inset 0 0 0 1px var(--ptf-accent-1);
}

.agreement-checkbox
	input[type='checkbox']:checked
	+ label
	.ptf-checkbox__checkmark::after {
	display: block;
}

.agreement-checkbox input[type='checkbox'] {
	display: none;
	position: absolute;
	left: -100%;
	top: 50%;
}
.contact_form {
	input {
		font-size: 2.5rem !important;
	}
}

@media (max-width: 1100px) {
	.ptf-is--home-agency .ptf-custom--3993 {
		background-position: -378px;
	}
}
@media (max-width: 991px) {
	.ptf-is--home-agency .ptf-custom--3993 {
		background-image: none;
	}
}
