/* HTML
-------------------------------------------- */

html {
	font-size: $base_font_size;

	@include mq('medium') {
		font-size: $base_font_size - 1px;
	}
	@include mq('small') {
		font-size: $base_font_size - 2px;
	}

	::-webkit-scrollbar {
		width: 6px;
	}

	::-webkit-scrollbar-track {
		background-color: var(--ptf-color-17);
	}

	::-webkit-scrollbar-thumb {
		background-color: var(--ptf-color-1);
	}
}

/* SELECTION
-------------------------------------------- */

@include selection {
	color: var(--ptf-color-white);

	background-color: var(--ptf-accent-1);
}

/* BODY
-------------------------------------------- */

body {
	min-width: px2rem(320px);
	margin: 0 !important;
	padding: 0;

	color: var(--ptf-color-2);
	font-family: var(--ptf-primary-font);
	font-size: px2rem(16px);
	line-height: 1.625;
	letter-spacing: -0.025em;

	background-color: var(--ptf-color-white);

	@if $smooth_font {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

/* IMAGE
-------------------------------------------- */

img {
	max-width: 100%;
	height: auto;
}

/* PARAGRAPH
-------------------------------------------- */

p {
	margin: 0 0 px2rem(30px);

	&:empty {
		display: none;
	}

	&:last-of-type {
		margin-bottom: 0;
	}
}

/* STRONG
-------------------------------------------- */

strong {
	font-weight: 700;
}

/* MARK
-------------------------------------------- */

mark {
	color: var(--ptf-color-white);

	background-color: var(--ptf-accent-1);
}

/* LINK
-------------------------------------------- */

a {
	text-decoration: none;
	color: inherit;
	display: inline-block;

	transition: all var(--ptf-transition-duration) var(--ptf-transition-easing);

	&:hover,
	&:focus,
	&:active {
		text-decoration: none;

		outline: none;
	}

	&:hover {
		color: var(--ptf-accent-1);

		.ptf-is--home-dark & {
			color: var(--ptf-color-white);
		}
	}
}

a.main-link {
	&:after {
		content: '';
		height: 1px;
		width: 100%;
		display: block;
		background: #1d1d27;
		-webkit-transition: 0.2s;
		-moz-transition: 0.2s;
		-ms-transition: 0.2s;
		-o-transition: 0.2s;
		transition: 0.2s;
	}

	&:hover:after {
		width: 0;
	}
}

/* HEADINGS
-------------------------------------------- */

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	margin: 0;
	padding: 0;
	color: var(--ptf-color-black);
	font-family: var(--ptf-primary-font);
	line-height: 1.3;
	font-weight: 700;
}

h1,
.h1 {
	font-size: px2rem(60px);
	line-height: 1.2;
	font-weight: 800;
	@media (max-width: 576px) {
		font-size: px2rem(50px);
	}

	@include mq('xlarge', min) {
		&.large-heading {
			font-size: px2rem(80px);
			line-height: 1.1;
		}
	}
}

h2,
.h2 {
	font-size: px2rem(48px);
}

h3,
.h3 {
	font-size: px2rem(36px);
	line-height: 1.2;
}

h4,
.h4 {
	font-size: px2rem(30px);
}

h5,
.h5 {
	font-size: px2rem(24px);
}

h6,
.h6 {
	font-size: px2rem(20px);
}

/* LIST
-------------------------------------------- */

ul,
ol {
	margin: 0;
	padding: 0;

	list-style-position: inside;
}

/* DIVIDER
-------------------------------------------- */

hr {
	margin: px2rem(30px) 0;

	border: none;
	border-bottom: 2px solid var(--ptf-color-black);
}

/* ADDRESS
-------------------------------------------- */

address {
	margin: 0 0 px2rem(25px);

	color: var(--ptf-accent-1);
	font-size: px2rem(28px);
	font-weight: 700;
	font-style: normal;
}

/* BLOCKQUOTE
-------------------------------------------- */

blockquote {
	position: relative;

	margin: 0 0 px2rem(30px);
	padding: 0;

	color: var(--ptf-color-black);
	font-size: px2rem(30px);
	line-height: 1.6;
	font-weight: 700;

	p {
		margin-bottom: 0;
	}
}

cite {
	display: block;

	margin: px2rem(16px) 0 0;

	text-transform: uppercase;
	color: var(--ptf-color-3);
	font-size: px2rem(14px);
	line-height: 1.42;
	font-style: normal;
}

/* PREFORMATTED
-------------------------------------------- */

code,
kbd {
	padding: px2rem(5px) px2rem(8px);

	color: var(--ptf-color-white);
	font-family: Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New',
		monospace;
	font-size: 87.5%;

	border-radius: 0;
	background-color: var(--ptf-accent-1);
}

a > code {
	color: var(--ptf-color-white);
}

pre {
	max-width: 100%;
	margin: 0 0 px2rem(32px);
	padding: px2rem(32px);

	white-space: pre-wrap;
	color: var(--ptf-color-black);
	font-family: Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New',
		monospace;

	background-color: var(--ptf-color-4);

	tab-size: 4;
	clear: both;
	overflow: auto;

	code {
		color: var(--ptf-color-white);
	}
}

/* FORM / INPUT / TEXTAREA / SELECT
-------------------------------------------- */

form {
	max-width: 100%;
}

label {
	position: relative;

	display: block;

	width: 100%;
	margin-bottom: px2rem(10px);

	color: var(--ptf-color-black);
	font-size: px2rem(24px);
	line-height: 1.5;

	user-select: none;

	@include mq('large', min) {
		&::before {
			content: attr(data-number);

			position: absolute;
			top: calc(50% - 0.75em);
			left: px2rem(-60px);

			color: var(--ptf-color-3);
			font-size: px2rem(14px);
		}
	}
}

input[type='text'],
input[type='date'],
input[type='email'],
input[type='password'],
input[type='tel'],
input[type='url'],
input[type='search'],
input[type='number'],
input[type='file'],
textarea,
select {
	display: block;

	width: 100%;
	margin: 0;
	padding: 0 0 px2rem(10px) 0;

	color: var(--ptf-color-black);
	font-size: px2rem(16px);

	border: none;
	border-bottom: 1px solid var(--ptf-color-14);
	border-radius: 0;
	outline: none;
	background-color: transparent;
	box-shadow: none;

	transition: all var(--ptf-transition-duration) var(--ptf-transition-easing);

	appearance: none;

	&.is-large {
		font-size: px2rem(36px);
	}

	&.style-2 {
		padding: px2rem(13px) px2rem(23px);

		font-size: px2rem(14px);

		border: 1px solid var(--ptf-color-3);
		background-color: var(--ptf-white-color);
	}
}

select {
	background-image: url("data:image/svg+xml;utf8,<svg fill='%232d2d2d' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
	background-repeat: no-repeat;
	background-position: right 0 bottom 50%;
	background-size: 18px 18px;

	&.is-large {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'%3E%3Cpath d='M32 46.8c-1.2 0-2.4-.4-3.4-1.3L1.8 20.3c-.7-.7-.7-1.8-.1-2.5.7-.7 1.8-.7 2.5-.1L31 42.9c.5.5 1.4.5 2 0l26.8-25.2c.7-.7 1.8-.6 2.5.1s.6 1.8-.1 2.5L35.4 45.4c-1 .9-2.2 1.4-3.4 1.4z'/%3E%3C/svg%3E");
		background-size: 30px 30px;
	}
}

textarea {
	width: 100%;
	max-width: 100%;

	transition: height 0s;
}

button,
input[type='submit'] {
	&:focus {
		outline: none;
	}
}

input[type='text'],
input[type='date'],
input[type='email'],
input[type='password'],
input[type='tel'],
input[type='url'],
input[type='search'],
input[type='number'],
input[type='file'],
textarea,
select {
	@include input-placeholder {
		color: var(--ptf-color-3);

		opacity: 1;
	}

	&:focus {
		border-color: var(--ptf-color-black);

		@include input-placeholder {
			opacity: 0;
		}
	}
}

.ptf-checkbox {
	position: relative;

	display: flex;
	align-items: center;

	padding-left: px2rem(42px);

	color: var(--ptf-color-black);
	font-size: px2rem(14px);

	user-select: none;
	cursor: pointer;

	&__checkmark {
		position: absolute;
		top: 50%;
		left: 0;

		display: flex;
		align-items: center;
		justify-content: center;

		width: px2rem(25px);
		height: px2rem(25px);

		background-color: transparent;
		box-shadow: inset 0 0 0 1px var(--ptf-color-3);

		transform: translateY(-50%);

		&::after {
			content: '\eb9d';

			display: none;

			color: var(--ptf-color-white);
			font-family: var(--ptf-linear-icons-light);
		}
	}

	input[type='checkbox']:checked ~ .ptf-checkbox__checkmark {
		background-color: var(--ptf-accent-1);
		box-shadow: inset 0 0 0 1px var(--ptf-accent-1);

		&::after {
			display: block;
		}
	}
}

.ptf-form-group {
	position: relative;

	display: block;

	margin-bottom: px2rem(30px);
}

.ptf-form-row {
	display: grid;

	grid-gap: px2rem(30px);
	grid-template-columns: repeat(1, 1fr);

	&.two-col {
		grid-template-columns: repeat(2, 1fr);
		@include mq('medium') {
			grid-gap: 0;
			grid-template-columns: repeat(1, 1fr);
		}
	}

	&.three-col {
		grid-template-columns: repeat(3, 1fr);
		@include mq('medium') {
			grid-gap: 0;
			grid-template-columns: repeat(1, 1fr);
		}
	}
}

/* DEFINITION LIST
-------------------------------------------- */

dl {
	margin-bottom: 0;

	dt {
		color: var(--ptf-color-black);
		font-size: px2rem(20px);
		font-weight: 700;
	}

	dd {
		font-size: px2rem(15px);
		line-height: 1.6;
	}
}

/* TABLE
-------------------------------------------- */

table {
	width: 100%;
	max-width: 100%;
	margin: 0;

	border-collapse: collapse;

	td,
	th {
		padding: px2rem(10px) px2rem(16px);

		border: 1px solid;
		border-color: var(--ptf-color-14) !important;
	}

	tr th,
	thead th {
		color: var(--ptf-color-black);
		font-weight: 700;
	}
}
