/* PAGE: BLOG
-------------------------------------------- */

/* SECTION: POST
-------------------------------------------- */

.ptf-post {
	position: relative;

	&__link {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;

		width: 100%;
		height: 100%;
	}

	&__meta {
		span + span {
			&::before {
				content: '/';

				margin-right: px2rem(8px);
				margin-left: px2rem(8px);
			}
		}
	}

	&__media {
		position: relative;

		overflow: hidden;

		img {
			width: 100%;

			transition:
				transform calc(var(--ptf-transition-duration) * 3)
					var(--ptf-transition-easing),
				filter var(--ptf-transition-duration)
					var(--ptf-transition-easing);

			backface-visibility: hidden;
			will-change: transform;
		}
	}

	&:hover {
		.ptf-post__media img {
			transform: scale(1.05);
		}
	}
}

/* SECTION: STYLE 1
-------------------------------------------- */

.ptf-post--style-1 {
	--ptf-post-header-indent: #{px2rem(40px)};

	.ptf-post__header {
		padding-top: var(--ptf-post-header-indent);
	}

	.ptf-post__meta {
		margin-bottom: px2rem(15px);

		text-transform: uppercase;
		color: var(--ptf-color-black);
		font-size: px2rem(14px);

		span.cat {
			font-weight: 700;
		}
	}

	.ptf-post__title {
		line-height: 1.16;
	}
}

/* SECTION: STYLE 2
-------------------------------------------- */

.ptf-post--style-2 {
	overflow: hidden;

	.ptf-post__media {
		position: relative;

		@include mq('large') {
			&__content {
				display: none;
			}
		}
		@include mq('large', min) {
			&__content {
				position: absolute;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 2;

				.ptf-post__meta,
				.ptf-post__title {
					color: var(--ptf-color-white);
				}
			}
		}

		&__content {
			padding: px2rem(20px) px2rem(24px);
		}

		@media (min-width: 1920px) {
			&__content {
				padding: px2rem(45px);
			}
		}

		&::after {
			content: '';

			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 1;

			background-color: hsla(0, 0%, 0%, 0.1);
			background-image: linear-gradient(
				to top,
				hsla(0, 0%, 0%, 0.5),
				transparent 55%
			);
		}
	}

	.ptf-post__content {
		display: flex;
		flex-direction: column;

		width: 100%;
		padding: px2rem(20px) px2rem(24px);

		border: solid 1px var(--ptf-color-black);
		border-top: none;
		background-color: var(--ptf-color-white);

		transition: transform var(--ptf-transition-duration)
			var(--ptf-transition-duration);

		@include mq('large', min) {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 3;

			display: flex;
			flex-direction: column;

			height: 100%;

			border-top: solid 1px var(--ptf-color-black);

			transform: translateY(100%);
		}

		@media (min-width: 1920px) {
			padding: px2rem(45px);
		}
	}

	.ptf-post__meta {
		margin-bottom: px2rem(15px);

		text-transform: uppercase;
		color: var(--ptf-color-black);
		font-size: px2rem(14px);

		span.cat {
			font-weight: 700;
		}
	}

	.ptf-post__title {
		line-height: 1.16;

		@media (max-width: 1919px) {
			font-size: px2rem(28px);
		}
	}

	.ptf-post__excerpt {
		margin-top: px2rem(30px);

		color: var(--ptf-color-black);
		font-size: px2rem(18px);

		@media (max-width: 1919px) {
			display: none;
		}
	}

	.ptf-post__footer {
		margin-top: px2rem(30px);

		color: var(--ptf-color-black);

		@include mq('large', min) {
			margin-top: auto;
		}

		.ptf-read-more-link {
			text-transform: uppercase;
			font-size: px2rem(14px);
			font-weight: 700;
		}
	}

	&:hover {
		.ptf-post__content {
			transform: translateY(0);
		}
	}
}

/* SECTION: STYLE 3
-------------------------------------------- */

.ptf-post--style-3 {
	display: grid;

	grid-template-columns: px2rem(440px) 1fr;
	grid-gap: px2rem(70px);
	@include mq('large') {
		grid-gap: px2rem(35px);
	}
	@include mq('medium') {
		grid-template-columns: 1fr;
	}

	.ptf-post__content {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.ptf-post__meta {
		margin-bottom: px2rem(30px);

		color: var(--ptf-color-3);
		font-size: px2rem(14px);

		span {
			display: block;

			&::before {
				display: none;
			}
		}

		span.cat {
			text-transform: uppercase;
			color: var(--ptf-color-black);
			font-size: px2rem(12px);
			font-weight: 700;
		}
	}

	.ptf-post__info {
		margin-top: auto;
		padding-top: px2rem(80px);

		@include mq('medium') {
			padding-top: px2rem(40px);
		}

		a {
			font-size: px2rem(14px);

			span {
				color: var(--ptf-color-black);
			}

			i {
				margin-right: px2rem(10px);

				font-size: px2rem(16px);
			}
		}

		a + a {
			margin-left: px2rem(50px);
		}
	}
}

.grid-item:not(:last-of-type) .ptf-post--style-3 {
	padding-bottom: px2rem(15px);

	border-bottom: 1px solid var(--ptf-color-14);
}

/* SECTION: STYLE 4
-------------------------------------------- */

.ptf-post--style-4 {
	@extend .ptf-post--style-2;

	.ptf-post__content {
		border: none;
		background-color: var(--ptf-accent-1);
	}

	.ptf-post__title,
	.ptf-post__footer {
		color: var(--ptf-color-white);

		a:hover {
			color: inherit;
		}
	}
}

.grid-slider {
	.slick-dots {
		bottom: 50px;
		text-align: right;
		right: 75px;
	}
	.slick-dots li button::before {
		border-color: #fff;
	}
	.slick-dots li.slick-active button::before {
		border-color: var(--ptf-accent-1);
	}
}
// masonry
.my-masonry-grid {
	display: -webkit-box; /* Not needed if autoprefixing */
	display: -ms-flexbox; /* Not needed if autoprefixing */
	display: flex;
	margin-left: -30px; /* gutter size offset */
	width: auto;
}
.my-masonry-grid_column {
	padding-left: 30px; /* gutter size */
	background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
	/* change div to reference your elements you put in <Masonry> */
	background: grey;
	margin-bottom: 30px;
}

// .ptf-isotope-grid .grid-item {
//   padding-bottom: 15px;
// }
.ptf-read-more-link {
	transition: 0.3s;
	padding: 0 12px;

	&:hover {
		background: #000;
		padding: 8px 12px;
		color: var(--ptf-color-white);
	}
}

/* SECTION: STYLE 5
-------------------------------------------- */

.ptf-post--style-5 {
	overflow: hidden;
	background-color: var(--ptf-color-white);

	.ptf-post__media {
		position: relative;

		&::after {
			content: '';

			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 1;
		}

		&__content {
			padding: 1rem 10px;
		}
	}

	.ptf-post__content {
		display: flex;
		flex-direction: column;

		width: 100%;
		padding: px2rem(10px) px2rem(10px);

		border: solid 1px var(--ptf-color-black);
		border-top: none;
		background-color: var(--ptf-color-white);

		transition: transform var(--ptf-transition-duration)
			var(--ptf-transition-duration);

		@include mq('large', min) {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 3;

			display: flex;
			flex-direction: column;

			height: 100%;

			border-top: solid 1px var(--ptf-color-black);

			transform: translateY(100%);
		}

		@media (max-width: 991px) {
			display: none;
		}
	}

	.ptf-post__meta {
		margin-bottom: px2rem(15px);

		text-transform: uppercase;
		color: var(--ptf-color-black);
		font-size: px2rem(14px);

		span.cat {
			font-weight: 700;
		}
	}

	.ptf-post__title {
		line-height: 1;
		font-size: px2rem(14px);
		font-weight: 700;
		color: var(--ptf-color-black);

		@media (max-width: 1919px) {
			font-size: px2rem(28px);
		}
	}

	.ptf-post__excerpt {
		color: var(--ptf-color-black);
		font-size: px2rem(14px);

		@media (max-width: 1919px) {
			display: none;
		}
	}

	.ptf-post__footer {
		margin-top: px2rem(30px);
		color: var(--ptf-color-black);

		@include mq('large', min) {
			margin-top: auto;
		}

		.ptf-read-more-link {
			text-transform: uppercase;
			font-size: px2rem(14px);
			font-weight: 700;
		}
	}

	&:hover {
		.ptf-post__content {
			transform: translateY(0);
		}
	}
}
