// VARIABLES
$smooth_font: true;
$base_font_size: 16px;

:root {
	// Accent colors
	@include defineColorHSL('--ptf-accent-1', 264, 100%, 67%); // #f1698e
	@include defineColorHSL('--ptf-accent-2', 343.7, 82.9%, 67.8%); // #f1698e
	@include defineColorHSL('--ptf-accent-3', 343.7, 82.9%, 67.8%); // #f1698e
	@include defineColorHSL('--ptf-accent-4', 343.7, 82.9%, 67.8%); // #f1698e

	// Global colors
	@include defineColorHSL('--ptf-color-white', 0, 0%, 100%); // #fff;
	@include defineColorHSL('--ptf-color-black', 0, 0%, 0%); // #000;
	@include defineColorHSL('--ptf-color-1', 0, 0%, 80%); // #ccc
	@include defineColorHSL('--ptf-color-2', 0, 0%, 40%); // #666
	@include defineColorHSL('--ptf-color-3', 0, 0%, 60%); // #999
	@include defineColorHSL('--ptf-color-4', 0, 0%, 97%); // #f7f7f7
	@include defineColorHSL('--ptf-color-5', 213, 60%, 97%); // #f3f7fc
	@include defineColorHSL('--ptf-color-6', 0, 0%, 85%); // #dadada
	@include defineColorHSL('--ptf-color-7', 0, 0%, 97%); // #f8f8f8
	@include defineColorHSL('--ptf-color-8', 0, 0%, 95%); // #f3f3f3
	@include defineColorHSL('--ptf-color-9', 0, 0%, 96%); // #f6f6f6
	@include defineColorHSL('--ptf-color-10', 0, 0%, 87%); // #dfdfdf
	@include defineColorHSL('--ptf-color-11', 38, 22%, 93%); // #f1eee9
	@include defineColorHSL('--ptf-color-12', 0, 0%, 7%); // #131313
	@include defineColorHSL('--ptf-color-13', 0, 0%, 50%); // #7f7f7f
	@include defineColorHSLA('--ptf-color-14', 0, 0%, 0%, 15%); // #000, 15%
	@include defineColorHSL('--ptf-color-15', 12, 29%, 97%); // #f9f5f4
	@include defineColorHSL('--ptf-color-16', 0, 0%, 11%); // #1d1d1d
	@include defineColorHSL('--ptf-color-17', 0, 0%, 92%); // #eaeaea
	@include defineColorHSLA('--ptf-color-18', 0, 0%, 0%, 80%); // #000, 80%
	@include defineColorHSL('--ptf-color-19', 45, 17%, 95%); // #f5f4f1
	// Fonts
	--ptf-primary-font: 'Cerebri Sans';
	--ptf-secondary-font: 'Amiri';
	// Icons
	--ptf-socicon: 'Socicon';
	--ptf-linear-icons-light: LineIconsPro Light;
	--ptf-linear-icons-regular: LineIconsPro Regular;
	// Transition
	--ptf-transition-duration: 300ms;
	--ptf-transition-easing: ease-in-out;
	// Others
	--ptf-navbar-height: 80px;
}

// SCALES
$scales: (
	's0': 200px,
	's1': 90px,
	's2': 60px,
	's3': 46px,
	's4': 36px,
	's5': 30px,
	's6': 28px,
	's7': 22px,
	's8': 18px,
	's9': 17px,
	's10': 16px,
	's11': 15px,
	's12': 14px,
);

// BREAKPOINTS
$breakpoints: (
	'small': 576px,
	'medium': 768px,
	'large': 992px,
	'xlarge': 1200px,
	'xxlarge': 1400px,
	'xxxlarge': 1500px,
);

// Z-INDEXES
$z-indexes: (
	'default': 1,
	'header': 30,
	'navbar': 50,
	'site-overlay': 60,
	'slide-menu': 70,
	'offcanvas-menu': 70,
	'header-slide': 80,
	'tooltip-image': 90,
	'custom-cursor': 100,
	'site-preloader': 9999,
);

// SOCIAL COLORS
$social-colors: (
	'internet': #1a55f8,
	'moddb': #cc0300,
	'indiedb': #77c628,
	'traxsource': #40a0ff,
	'gamefor': #506f82,
	'pixiv': #049cff,
	'myanimelist': #2e51a2,
	'blackberry': #000,
	'wickr': #f29100,
	'spip': #e00d6f,
	'napster': #000,
	'beatport': #94d500,
	'hackerone': #ec1075,
	'hackernews': #ff6601,
	'smashwords': #4181c3,
	'kobo': #bf0000,
	'bookbub': #e70005,
	'mailru': #fda840,
	'gitlab': #e65228,
	'instructables': #f8b514,
	'portfolio': #54afff,
	'codered': #ff033b,
	'origin': #f56c2e,
	'nextdoor': #01b247,
	'udemy': #17aa1c,
	'livemaster': #e76d00,
	'crunchbase': #0288d1,
	'homefy': #7dcda3,
	'calendly': #00a3fa,
	'realtor': #d52228,
	'tidal': #01ffff,
	'qobuz': #298fbf,
	'natgeo': #222,
	'mastodon': #2986d6,
	'unsplash': #000,
	'homeadvisor': #ef8b1d,
	'angieslist': #299f37,
	'codepen': #000,
	'slack': #4b6bc6,
	'openaigym': #29a8b3,
	'logmein': #45b6f3,
	'fiverr': #0db62a,
	'gotomeeting': #fd7a2b,
	'aliexpress': #e92c00,
	'guru': #4c81c0,
	'appstore': #007aff,
	'homes': #f7841b,
	'zoom': #2d8cff,
	'alibaba': #ff6a00,
	'craigslist': #561a8b,
	'wix': #0096ff,
	'redfin': #c82022,
	'googlecalendar': #3d81f6,
	'shopify': #5c6ac4,
	'freelancer': #0088ca,
	'seedrs': #7fbb31,
	'bing': #008485,
	'doodle': #0064dc,
	'bonanza': #ff4950,
	'squarespace': #121212,
	'toptal': #4c73aa,
	'gust': #1e2e3e,
	'ask': #cf0000,
	'trulia': #20bf63,
	'loomly': #00425f,
	'ghost': #33393c,
	'upwork': #5bbc2f,
	'fundable': #1d181f,
	'booking': #003580,
	'googlemaps': #4285f4,
	'zillow': #0074e4,
	'niconico': #000,
	'toneden': #777bf9,
	'augment': #e71204,
	'bitbucket': #243759,
	'fyuse': #ff3143,
	'yt-gaming': #e91d00,
	'sketchfab': #00a5d6,
	'mobcrush': #fe0,
	'microsoft': #666,
	'pandora': #224099,
	'messenger': #0084ff,
	'gamewisp': #f8a853,
	'bloglovin': #000,
	'tunein': #36b4a7,
	'gamejolt': #191919,
	'trello': #0079bf,
	'spreadshirt': #00b2a6,
	'500px': #58a9de,
	'8tracks': #122c4b,
	'airbnb': #ff5a5f,
	'alliance': #144587,
	'amazon': #f90,
	'amplement': #0996c3,
	'android': #8ec047,
	'angellist': #000,
	'apple': #b9bfc1,
	'appnet': #494949,
	'baidu': #2629d9,
	'bandcamp': #619aa9,
	'battlenet': #0096cd,
	'mixer': #1fbaed,
	'bebee': #f28f16,
	'bebo': #ef1011,
	'behance': #000,
	'blizzard': #01b2f1,
	'blogger': #ec661c,
	'buffer': #000,
	'chrome': #757575,
	'coderwall': #3e8dcc,
	'curse': #f26522,
	'dailymotion': #004e72,
	'deezer': #32323d,
	'delicious': #020202,
	'deviantart': #c5d200,
	'diablo': #8b1209,
	'digg': #1d1d1b,
	'discord': #7289da,
	'disqus': #2e9fff,
	'douban': #3ca353,
	'draugiem': #ffa32b,
	'dribbble': #e84d88,
	'drupal': #00598e,
	'ebay': #333,
	'ello': #000,
	'endomodo': #86ad00,
	'envato': #597c3a,
	'etsy': #f56400,
	'facebook': #3e5b98,
	'feedburner': #fc0,
	'filmweb': #ffc404,
	'firefox': #484848,
	'flattr': #f67c1a,
	'flickr': #1e1e1b,
	'formulr': #ff5a60,
	'forrst': #5b9a68,
	'foursquare': #f94877,
	'friendfeed': #2f72c4,
	'github': #221e1b,
	'goodreads': #463020,
	'google': #4285f4,
	'googlescholar': #4285f4,
	'googlegroups': #4f8ef5,
	'googlephotos': #212121,
	'googleplus': #dd4b39,
	'grooveshark': #000,
	'hackerrank': #2ec866,
	'hacker-news': #f60,
	'hearthstone': #ec9313,
	'hellocoton': #d30d66,
	'heroes': #2397f7,
	'smashcast': #3498db,
	'horde': #84121c,
	'houzz': #7cc04b,
	'icq': #7ebd00,
	'identica': #000,
	'imdb': #e8ba00,
	'instagram': #c83b8b,
	'issuu': #f26f61,
	'istock': #000,
	'itunes': #ff5e51,
	'keybase': #ff7100,
	'lanyrd': #3c80c9,
	'lastfm': #d41316,
	'line': #00b901,
	'linkedin': #3371b7,
	'livejournal': #09c,
	'lyft': #ff00bf,
	'macos': #000,
	'mail': #f3894d,
	'medium': #000,
	'meetup': #e2373c,
	'mixcloud': #000,
	'modelmayhem': #000,
	'mumble': #5ab5d1,
	'myspace': #323232,
	'newsvine': #075b2f,
	'nintendo': #f58a33,
	'npm': #c12127,
	'odnoklassniki': #f48420,
	'openid': #f78c40,
	'opera': #ff1b2d,
	'outlook': #0072c6,
	'overwatch': #9e9e9e,
	'patreon': #f96854,
	'paypal': #009cde,
	'periscope': #3aa4c6,
	'persona': #e6753d,
	'pinterest': #c92619,
	'play': #000,
	'player': #6e41bd,
	'playstation': #000,
	'pocket': #ed4055,
	'qq': #4297d3,
	'quora': #cb202d,
	'raidcall': #073558,
	'ravelry': #b6014c,
	'reddit': #e74a1e,
	'renren': #2266b0,
	'researchgate': #0cb,
	'residentadvisor': #b3be1b,
	'reverbnation': #000,
	'rss': #f26109,
	'sharethis': #01bf01,
	'skype': #28abe3,
	'slideshare': #4ba3a6,
	'smugmug': #acfd32,
	'snapchat': #fffa37,
	'songkick': #f80046,
	'soundcloud': #fe3801,
	'spotify': #7bb342,
	'stackexchange': #2f2f2f,
	'stackoverflow': #fd9827,
	'starcraft': #002250,
	'stayfriends': #f08a1c,
	'steam': #171a21,
	'storehouse': #25b0e6,
	'strava': #fc4c02,
	'streamjar': #503a60,
	'stumbleupon': #e64011,
	'swarm': #fc9d3c,
	'teamspeak': #465674,
	'teamviewer': #168ef4,
	'technorati': #5cb030,
	'telegram': #08c,
	'tripadvisor': #4b7e37,
	'tripit': #1982c3,
	'triplej': #e53531,
	'tumblr': #45556c,
	'twitch': #6441a5,
	'twitter': #0cf,
	'uber': #000,
	'ventrilo': #77808a,
	'viadeo': #e4a000,
	'viber': #7b519d,
	'viewbug': #2b9fcf,
	'vimeo': #51b5e7,
	'vine': #00b389,
	'vkontakte': #5a7fa6,
	'warcraft': #1eb10a,
	'wechat': #09b507,
	'weibo': #e31c34,
	'whatsapp': #20b038,
	'wikipedia': #000,
	'windows': #00bdf6,
	'wordpress': #464646,
	'wykop': #328efe,
	'xbox': #92c83e,
	'xing': #005a60,
	'yahoo': #6e2a85,
	'yammer': #1175c4,
	'yandex': #f00,
	'yelp': #c83218,
	'younow': #61c03e,
	'youtube': #e02a20,
	'zapier': #ff4a00,
	'zerply': #9dbc7a,
	'zomato': #cb202d,
	'zynga': #dc0606,
);
