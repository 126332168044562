.slick-dots {
	li {
		button {
			width: 14px;
			height: 14px;
			border-radius: 50%;
			position: relative;
			cursor: pointer !important;

			&::before {
				content: '';
				font-size: 0;
				opacity: 1;
				border: 2px solid var(--ptf-accent-1);
				width: 100%;
				height: 100%;
				border-radius: 50%;
				transition: 0.3s ease-in-out;
			}
			&::after {
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				transform: scale(0);
				background: var(--ptf-accent-1);
				transition: 0.3s ease-in-out;
				border-radius: inherit;
				z-index: 5;
			}
		}
		&.slick-active {
			button {
				&::after {
					transform: scale(0.5);
				}
			}
		}
	}
}

.slide-portfolio {
	/* the parent */
	.slick-list {
		margin-left: -10px;
		margin-right: -10px;
		@media (max-width: 575px) {
			margin-left: 0;
			margin-right: 0;
		}
	}
	.slick-slide {
		padding-left: 10px;
		padding-right: 10px;
		@media (max-width: 575px) {
			padding-left: 0;
			padding-right: 0;
		}
	}
	.slick-prev {
		left: -50px;
	}
	.slick-next {
		right: -50px;
	}
}

// slick
.slick-prev,
.slick-next {
	width: 45px;
	height: 45px;
	box-shadow: inset 0 0 0 1px var(--ptf-color-3);
	z-index: 1;
	transition: 0.5s;
	top: 41%;
	cursor: pointer !important;
	@media screen and (max-width: 991px) {
		display: none !important;
	}
	&::before {
		display: none;
	}
	&::after {
		content: '';
		background: url(/images/arrow.png) no-repeat;
		position: absolute;
		z-index: 555;
		height: 20px;
		width: 16px;
		top: 11px;
		right: 19px;
		transition: 0.5s;
	}
	&:hover {
		background: var(--ptf-accent-1);
		box-shadow: inset 0 0 0 1px var(--ptf-accent-1);
		&::after {
			filter: invert(1);
		}
	}
}

.slick-prev {
	@media screen and (max-width: 1599px) {
		left: 0;

		border-color: #fff;
		&.slick-disabled {
			background: transparent;
			border-color: #555;
			&::after {
				filter: invert(0.5);
			}
		}
	}
	&::after {
		display: block;
		transform: rotate(-180deg);
	}
}
.slick-next {
	&::after {
		top: 15px;
		right: 11px;
	}
	@media screen and (max-width: 1599px) {
		right: 0;

		border-color: #fff;

		&.slick-disabled {
			background: transparent;

			&::after {
				filter: invert(0.5);
			}
		}
	}
}

.arrow-none {
	.slick-prev,
	.slick-next {
		display: none !important;
	}
}

.has-dark-color {
	.slick-prev::after,
	.slick-next::after {
		filter: invert(1);
	}
}

.slick-center .ptf-work--style-3 {
	.ptf-work__media img {
		filter: grayscale(0);
	}
}

.slide-portfolio {
	.ptf-work--style-3 {
		.ptf-work__media img {
			@media (min-width: 1920px) {
				height: 469px;
			}
			@media (max-width: 1919px) {
				height: 392px;
			}
			@media (max-width: 1100px) {
				height: 308px;
			}
			@media (max-width: 991px) {
				height: 342px;
			}
			@media (max-width: 767px) {
				height: 371px;
			}
		}
	}
	@media (max-width: 991px) {
		.slick-dots {
			bottom: -58px;
		}
	}
}
